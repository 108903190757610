import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        userInfo:{},
        menus:[],
        auth:[],
        searchContent:''
    },
    mutations:{
        setUserInfo(state, userInfo){
            state.userInfo = userInfo
        },
        setMenus(state, menus){
            state.menus = menus
        },
        setSearch(state, searchContent){
            state.searchContent = searchContent
        },
        resetMenus(state){
            state.menus = [];
        },
        setAuth(state,auth){
            state.auth = auth
        }
    },
    getters:{

    },
    actions:{
        resetAction({ commit }){
            commit('reset')
        },
        resetMen({ commit }){
            commit('resetMenus')
        }
    },
    modules:{

    },
    plugins: [createPersistedState()]
})
